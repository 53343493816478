<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-experttopics-list')"
        @delete="helperDeleteItem(`/experttopics/${$route.params.id}`, null, 'apps-experttopics-list')"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
        :title="currentItem.name"
    >
    </b-card-actions>
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            @submit.stop.prevent="handleSubmit(updateItem)"
            @reset.prevent="resetForm"
        >
          <b-card>
          <!-- Status -->
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <!-- Name -->
          <validation-provider
              #default="validationContext"
              :name="$t('Expert Topic')"
              rules="required"
          >
            <b-form-group
                :label="$t('Expert Topic')"
                label-for="Name"
            >
              <b-form-input
                  id="Name"
                  v-model="currentItem.name"
                  trim
                  :placeholder="$t('Expert Topic')"
              />

              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{ $t(validation.name[0]) }}</small>
            </b-form-group>
          </validation-provider>
          </b-card>
          <b-card>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
            >
              {{ $t('Update') }}
            </b-button>
          </b-card>
        </b-form>
      </validation-observer>
  </component>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,

} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        id: '',
        name: '',
        status: ''
      },
      loaded: true,
      validation: {},
      validate: false,
    };
  },

  methods: {
    getItem() {
      this.helperGetItem(`/experttopics/${this.$route.params.id}`)
    },
    updateItem() {
      this.$refs.refFormObserver.validate().then((success) => {
        this.validation = {};
        this.validate = false;
        if (success) {
          this.helperUpdateItem(
            `/experttopics/${this.$route.params.id}`,
            this.currentItem
          )
        } else {
          this.loaded = false
          setTimeout(() => {
            this.loaded = true
          })
        }
      })
    },

    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

  },
  mounted() {
    this.getItem()
  },
};
</script>

<style>
</style>
